.registerSuccess {
    padding: 50px;
    background-color: white;
}
.marginSpacer.registration {
    margin-top:180px;
}
.formcontainer {
    padding-top:70px;
}
