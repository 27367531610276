.Impressum {
    background-color: #edefef;
}

.article {
    padding-bottom: 20px;
}

.Impressum .marginSpacer {
    margin-top: 262px;
}

.content-text {
    margin-top: 20px;
    padding: 20px 10px;
    background-color: #fff;
    margin-bottom: 0;

}

.aok-link {
    padding: 20px 0 0;
}

.aok-link  span{
    font-size: 17px;
}

.aok-link  a:hover {
    color: #008a34;
}

@media (min-width: 768px){
    .content-text {
        padding: 20px;
    }
}

.content-text:not(.content-text--full-width) {
    margin-top: 5px;
}

.content-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
}

.content-text__title {
    position: relative;
    min-height: 1px;
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;
}

@media (min-width: 992px) {
    .content-text__title {
        width: 33.33333%;
    }
}

@media (min-width: 1216px) {
    .content-text__title {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.h4 {
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1.21;
    font-weight: 200;
    font-size: 22px;
}

p {
    margin: 0 0 15px;
}

b {
    font-weight: 700;
}


.content-text__content {
    position: relative;
    min-height: 1px;
    padding-left: 4px;
    padding-right: 4px;
    width: 100%;
}

@media (min-width: 992px) {
    .content-text__content {
        width: 66.66667%;
    }
}

@media (min-width: 1216px) {
    .content-text__content {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.more-link {
    color: #008a34;
    text-decoration: none;
}

.more-link:hover {
    color: #008a34;
}

.more-link:after {
    display: inline !important;
    content: '\00a0»';
}

.content-text__paragraph {
    display: flex;
}

.content-text__paragraph p:first-child {
    margin-right: 15px;
}