/* Position and sizing of burger button */

.bm-burger-button {
  height: 20px;
  margin: 15px 0;
  position: fixed;
  width: 30px;

}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: green-white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 64px !important;
  width: 66px !important;
  top: 0 !important;
  right: 364px !important;
  z-index:10000;
}

/* Color/shape of close button cross */
.bm-cross {
  background: green-white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  height: 100%;
  position: fixed;
}

/* General sidebar styles */
.bm-menu {
  font-size: 1.15em;
  background-color: transparent;
  padding: 2.5em 1.5em 0;
}

/* Wrapper for item list */
.bm-item-list {
  color:white;
}
.close-button {
  position: absolute;
  left: 0px;
  cursor:pointer;
}
/* Styling of overlay */
.bm-overlay {
  background: white;
  left:0;
}

.right .bm-burger-button {
  left: initial;
  padding: 30px;
  right: 36px;
}

.bm-morph-shape {
  fill: green;
}

.bm-menu {
  background: transparent;

  a {
    color: white;

    &:hover {
      background-color:#838383;
      color: white;
      cursor: pointer;
    }
  }
}

.bm-item-list a {
  padding: 0.8em;

  span {
    font-weight: 700;
    margin-left: 10px;
  }
}

.bm-item:focus {
  outline: none;
}

a {
  color: white;
  text-decoration: none;

  &:hover,
  &:focus {
    color: grey;
    cursor: pointer;
  }
}