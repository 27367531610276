.hidden {
  display:none;
}
@font-face {
  font-family: "Frutiger";
  src: url("/typography/frutiger/c13b5491-0ad0-401e-8b39-03a88965ce5a.eot?#iefix");
  src: url("/typography/frutiger/c13b5491-0ad0-401e-8b39-03a88965ce5a.eot?#iefix") format("eot"), url("/typography/frutiger/1925eb3e-b843-445d-8072-f11f28e0562f.woff2") format("woff2"), url("/typography/frutiger/ef7e8467-66c5-4521-afc4-777f6cf72ec4.woff") format("woff"), url("/typography/frutiger/c1bbbd49-cc08-4ec8-a63d-8cbd6936c666.ttf") format("truetype"), url("/typography/frutiger/838d632a-68b5-43fe-8b43-78c397691c74.svg#838d632a-68b5-43fe-8b43-78c397691c74") format("svg");
  font-style: normal;
  font-weight: 200;
  font-display: swap; }

@font-face {
  font-family: "Frutiger";
  src: url("/typography/frutiger/4ae7b88b-fb5d-4cb5-9888-78298ad2a973.eot?#iefix");
  src: url("/typography/frutiger/4ae7b88b-fb5d-4cb5-9888-78298ad2a973.eot?#iefix") format("eot"), url("/typography/frutiger/60cae32f-adbc-4849-a2a7-51f8148b3332.woff2") format("woff2"), url("/typography/frutiger/3963cec0-c35f-41fa-adb6-4ef3fc0bb056.woff") format("woff"), url("/typography/frutiger/95a1e677-b284-4b7e-86f9-b684fba2e7da.ttf") format("truetype"), url("/typography/frutiger/e31c4487-0242-4de0-8b75-7e0330196edc.svg#e31c4487-0242-4de0-8b75-7e0330196edc") format("svg");
  font-style: normal;
  font-weight: 400;
  font-display: swap; }

@font-face {
  font-family: "Frutiger";
  src: url("/typography/frutiger/840b1eda-acf5-4788-867d-beaf576d7600.eot?#iefix");
  src: url("/typography/frutiger/840b1eda-acf5-4788-867d-beaf576d7600.eot?#iefix") format("eot"), url("/typography/frutiger/be2eb9d5-ee74-4084-b711-fe64163d1439.woff2") format("woff2"), url("/typography/frutiger/8b1cbfbd-4fa3-4330-8e32-552125871646.woff") format("woff"), url("/typography/frutiger/9b0d5970-235c-47a8-b94d-a7fe9dea7585.ttf") format("truetype"), url("/typography/frutiger/3071ab8f-494f-44e7-919c-4c46e8d45cbc.svg#3071ab8f-494f-44e7-919c-4c46e8d45cbc") format("svg");
  font-style: normal;
  font-weight: 700;
  font-display: swap; }

@font-face {
  font-family: "aok-icons-functional";
  src: url("/typography/aok-icons-functional.woff") format("woff"), url("/typography/aok-icons-functional.ttf") format("truetype"), url("/typography/aok-icons-functional.svg#aok-icons-functional") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

body {
  margin: 0;
  font-family: Frutiger, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
body h1 {
  font-family: "Frutiger" !Important;
  font-weight: 200;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
